.content-page {
    background-attachment: fixed;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.content-page::before {
    background: rgba(0, 0, 0, 0.75);
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.content-page .mt-16 {
    margin-top: 16px;
    display: block;
}

.content-page .mb-16 {
    margin-bottom: 16px;
    display: block;
}

.content-page h1 {
    font-size: clamp(36px, 1rem + 4vw, 42px);
    line-height: clamp(42px, 1rem + 4vw, 46px);
    margin-bottom: 2rem;
}

.content-page h2 {
    font-size: clamp(18px, 1rem + 4vw, 24px);
    line-height: clamp(24px, 1rem + 4vw, 36px);
    font-weight: 500;
    /* margin-top: 4rem; */
    margin-bottom: 1rem;
    letter-spacing: 0.3px;
}

.content-page p,
.content-page li {
    color: #c5c5c5;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    margin-bottom: 1rem;
}

.content-page ul {
    padding-left: 24px;
    margin-left: 10px;
    margin-top: 20px;
}

.content-page ul li {
    position: relative;
}

.content-page ul li::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 6px;
    width: 8px;
    height: 8px;
    background: #c5c5c5;
}

.content-page strong {
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .content-page h2 {
        /* margin-top: 3rem; */
    }
}