@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;600;700&family=Inter:wght@100..900&display=swap");

html {
  scroll-behavior: smooth;
}

@layer base {
  :root {
    --gradient: linear-gradient(90deg, #ff794c 0%, #5b9aba 100%);
    --shadow-gd: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #09050b 98.87%);
    --effect-gd: linear-gradient(180deg,
        rgba(153, 73, 45, 0) 1.95%,
        rgba(243, 0, 4, 0.5) 55.63%,
        rgba(255, 121, 76, 0.4) 68.86%);
    --whitelist-effect-gd: linear-gradient(180deg, rgba(153, 73, 45, 0.00) 1.95%, rgba(243, 0, 4, 0.50) 55.63%, rgba(255, 121, 76, 0.40) 68.86%);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background: theme("colors.black");
  color: theme("colors.white");
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font-size: 1rem;
  font-weight: normal;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.03em;
  font-family: "inter", sans-serif;
}

html,
body {
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: theme("colors.orange");
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px theme("colors.white"),
    inset -2px -2px 2px theme("colors.white");
}

.scrollTransparent::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollTransparent::-webkit-scrollbar-thumb {
  background: rgba(255, 164, 133, 0.3);
  box-shadow: none;
}

.scrollTransparent::-webkit-scrollbar-track,
.sidebar ul::-webkit-scrollbar-track,
.sidebar div::-webkit-scrollbar-track {
  background: transparent !important;
}

@layer components {
  button {
    @apply disabled:cursor-not-allowed disabled:!opacity-70;
  }

  .light-button {
    @apply bg-white text-black;
  }

  .dark-button {
    @apply bg-black text-white border-[1px] border-charlestonGreen hover:!text-white/70;
  }

  .primary-button {
    @apply bg-orange text-black hover:bg-white;
  }

  .gradient-cirlce {
    @apply before:absolute before:top-0 before:rotate-[111deg] before:opacity-60 before:blur-[250px] before:w-[250px] before:h-[550px] lg:before:w-[585px] lg:before:h-[1145px] before:z-0;
  }

  .whitelist-gradient-cirlce {
    @apply before:absolute before:-bottom-[40rem] before:-rotate-[90deg] before:opacity-60 before:blur-[250px] before:w-[250px] before:h-[550px] lg:before:w-[600px] lg:before:h-[1200px] before:z-0;
  }

  .connector:not(:first-child) {
    @apply md:before:absolute before:top-[42%] before:left-[-40%] before:w-[47px] before:h-[47px] before:bg-connector before:z-10 md:after:absolute after:top-1/2 after:left-[-65%] after:w-full after:h-3 after:bg-connectorLine after:bg-no-repeat after:bg-cover;
  }

  .left,
  .right {
    @apply relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black/70;
  }

  .sc-slider .swiper-slide:not(.swiper-slide-active)>div {
    @apply relative block before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black/70;
  }

  .db-slider .swiper-pagination {
    /* @apply -bottom-5 lg:-bottom-10; */
  }

  .db-slider .swiper-pagination-bullet {
    @apply w-10 lg:w-20 h-[6px] lg:h-2 bg-black rounded-full;
  }

  .planList li {
    @apply before:absolute before:left-0 before:top-0 before:w-4 before:h-4 before:bg-listIcon before:bg-cover before:bg-no-repeat before:bg-center;
  }

  .iconCard {
    @apply before:absolute before:right-0 before:blur-[100px] before:rounded-[298px];
  }
}

.gradient-cirlce::before {
  background: var(--effect-gd);
}

.whitelist-gradient-cirlce::before {
  background: var(--whitelist-effect-gd);
}

.vdGradient::before {
  background: var(--shadow-gd);
}

.swap-store::before {
  background: linear-gradient(180deg,
      rgba(153, 73, 45, 0) 1.95%,
      rgba(243, 0, 4, 0.5) 55.63%,
      rgba(255, 121, 76, 0.4) 68.86%);
}

.modal-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: theme("colors.white");
  border: 1.5px solid theme("colors.gray");
  border-radius: 6px;
  padding: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  align-self: start;
  cursor: pointer;
}

.modal-checkbox label::before {
  padding: 7px;
  border-radius: 3px;
}

.modal-checkbox input:checked+label::before {
  background: theme("colors.black");
}

.modal-checkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 10px;
  width: 7px;
  height: 16px;
  border: solid theme("colors.white");
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  border-radius: 2px;
}

.modal-checkbox input:checked+label:after {
  top: 1px;
  left: 5px;
  width: 6px;
  height: 12px;
}

.videoContain::after,
.videoContain::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 70%;
  height: 96%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;
}

.videoContain::before {
  left: 30px;
}

.videoContain::after {
  right: 30px;
}

@media (min-width: 768px) and (max-width: 1250px) {
  .videoContain::before {
    left: 100px;
  }

  .videoContain::after {
    right: 100px;
  }
}

.rotate {
  animation: rotation 35s infinite cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.antirotation {
  animation: antirotation 40s infinite cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

@keyframes antirotation {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.open-wallet-modal {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: red;
}

.StripeElement {
  width: 100%;
  padding: 23px 16px;
  border: 1px solid #242424;
  border-radius: 12px;
  margin-top: 20px;
}


.blog-details-container {
  max-width: 1080px;
  padding: 180px 15px;
  margin: auto;
}

.blog-details-container h1 {
  text-align: center;
  font-size: clamp(2rem, 2rem + 0.5vw, 3.5rem);
  line-height: clamp(2rem, 3rem + 0.5vw, 4.75rem);
  margin-bottom: 30px;
  font-weight: 600;
}

.blog-details-container h2 {
  text-align: left;
  font-size: clamp(22px, 2rem + 0.5vw, 24px);
  line-height: clamp(26px, 3rem + 0.5vw, 30px);
  margin-bottom: 30px;
  font-weight: 600;
}

.blog-details-container h3 {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}

.blog-details-container .date {
  color: #f6f6f680;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -.18px;
}

.blog-details-container p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.blog-details-container img {
  border-radius: 24px;
  margin-bottom: 30px;
}

.blog-details-container ul {
  margin-bottom: 16px;
  margin-left: 10px;
  padding-left: 24px;
}

.blog-details-container li {
  margin-bottom: 15px;
  position: relative;
}

.blog-details-container li::before {
  background: rgb(255, 120, 75);
  content: "";
  height: 8px;
  left: -20px;
  position: absolute;
  top: 9px;
  width: 8px;
}

@media (min-width: 390px) and (max-width: 767px) {
  .blog-details-container {
    padding: 90px 15px;
  }
}

.blog-detail-page::before {
  background: rgba(0, 0, 0, 0.75);
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 100%;
}